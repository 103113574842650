import React, { useState } from "react";
import styled from "styled-components";
import {
  tsWidth,
  tsHeight,
  tsLogo,
  sliderMainTitle,
  sliderMainPrice,
  sliderMainFinance,
  sliderDescr,
} from "@ecomm/typography";
import { Images } from "constants/Images";
import { LoadSpinner } from "./UI/LoadSpinner";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { useLocation } from "@reach/router";

const ImageHolder = styled.div`
  ${tsWidth};
  ${tsHeight};
  z-index: 0;
  position: absolute;
  /* opacity: 0.7; */
  display: block;
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  ${ccIpadPortrait`
    max-width: 100vw;
  `}
`;

const ProductName = styled.div`
  color: #ff3347;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  /* padding: 80px 0 0 93px; */
  position: absolute;
  left: 93px;
  top: 80px;
`;

const Logo = styled.div`
  /* background-image: url(${Images.globalGlowLogo}); */
  ${tsLogo};
  z-index: 2;
  display: block;
  width: 44px;
  height: 60px;
  position: absolute;
  top: 80px;
  left: 518px;
  display: none;
  /* left: 50%;
  margin-left: -22px; */
`;

const Title = styled.div`
  ${sliderMainTitle};
  color: white;
  display: block;
  position: absolute;
  z-index: 1;
  ${tsWidth};
  height: auto;
  text-align: center;
  left: 0px;
  top: 1098px;

  ${ccIpadPortrait`
    top: 730px;
  `}
`;

const SubContent = styled.div`
  color: white;
  display: block;
  position: absolute;
  z-index: 1;
  width: 730px;
  height: auto;
  text-align: center;
  left: 175px;
  top: 1230px;
  ${ccIpadPortrait`
    top: 770px;
    width: 100vw;
    padding-left: 100px;
    padding-right: 100px;
    left: 0px;
  `}
`;

const Subtitle = styled.div`
  ${sliderMainPrice};
  color: white;
  padding-bottom: 35px;
  ${ccIpadPortrait`
    padding-bottom: 8px;
  `}
`;
const Financing = styled.div`
  ${sliderMainFinance};
  padding-bottom: 39px;
  ${ccIpadPortrait`
    padding-bottom: 26px;
  `}
`;
const Description = styled.div`
  ${sliderDescr};
  width: 580px;
  margin: auto;
  ${ccIpadPortrait`
    width: 440px;
  `}
`;

interface Props {
  slideMainUrl: string | undefined;
  title: string | undefined;
  description: string | undefined;
  price: number;
  product: string;
  slug: string | undefined;
}

export const PackageSlideMain = (props: Props) => {
  const { slideMainUrl, title, description, price, slug } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);

  const location = useLocation();
  const locationArray = location.pathname.split("/");
  const intlLocation = locationArray[1];

  //slug: "ca_webstore", locale: EN_CA, currency: CAD

  const curCategory = locationArray[4];
  let product = "BIKE";
  switch (curCategory) {
    case "tread":
      product = "TREAD";
      break;
    case "treadplus":
      product = "TREAD+";
      break;
    case "bikeplus":
      product = "BIKE+";
      break;
    case "row":
      product = "ROW";
      break;
    default:
      break;
  }

  if (!preloadComplete) {
    // TODO: move this to dataUtils
    const promises: Promise<string>[] = [];
    const cacheImages = (arr: string[]) => {
      arr.forEach((src) => {
        const p = new Promise<string>((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => resolve(src);
          img.onerror = () => reject();
        });
        promises.push(p);
      });
      Promise.all(promises).then((results) => {
        setIsLoading(false);
        setPreloadComplete(true);
      });
    };
    if (slideMainUrl) cacheImages([slideMainUrl]);
  }

  const getPackagePrice = (price: number) => {
    return price / 100;
  };

  const getVariablePrice = (slug: string) => {
    if (slug.includes("tread") || slug.includes("ultimate"))
      return (
        <>
          <span></span>
        </>
      );
  };

  const getFinancingPrice = (price: number) => {
    return Math.ceil(price / 100 / 39);
  };

  const getSubContent = (slug: string | undefined) => {
    if (slug?.includes("opc")) {
      const rentalPrice =
        product === "BIKE"
          ? intlLocation === "ca"
            ? "129"
            : "89"
          : intlLocation === "ca"
          ? "169"
          : "119";
      return (
        <SubContent>
          <Subtitle>${rentalPrice} per month</Subtitle>
          <Financing></Financing>
          <Description>{description}</Description>
        </SubContent>
      );
    } else {
      return (
        <SubContent>
          <Subtitle>
            {title && getVariablePrice(title.toLocaleLowerCase())}$
            {price && getPackagePrice(price).toLocaleString()} with{" "}
            {props.product}
          </Subtitle>
          <Financing>
            As low as ${price && getFinancingPrice(price).toLocaleString()}/mo
            with 0% APR
          </Financing>
          <Description>{description}</Description>
        </SubContent>
      );
    }
  };

  return (
    <div className="relly">
      {isLoading ? (
        <LoadSpinner>Loading...</LoadSpinner>
      ) : (
        <ImageHolder>
          <StyledImage alt="" src={slideMainUrl} />
        </ImageHolder>
      )}
      <Logo></Logo>
      <Title>{title}</Title>
      <ProductName>Peloton &nbsp; {product}</ProductName>
      {getSubContent(slug)}
      {/* <SubContent>
        <Subtitle>
          {title && getVariablePrice(title.toLocaleLowerCase())}$
          {price && getPackagePrice(price).toLocaleString()} with{" "}
          {props.product}
        </Subtitle>
        <Financing>
          As low as ${price && getFinancingPrice(price).toLocaleString()}/mo
          with 0% APR
        </Financing>
        <Description>{description}</Description>
      </SubContent> */}
    </div>
  );
};
