import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Navigation } from "components/Navigation";
import { MainCanvas } from "components/UI/MainCanvas";

import { Images } from "constants/Images";
import styled from "styled-components";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { FeaturesListView } from "components/FeaturesListView";

const TabBack = styled.a`
  display: none;

  ${ccIpadPortrait`
    position: absolute;
    left: 122px;
    top: 48px;
    z-index: 20;
    color: #888b93;
    display: block;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    -webkit-letter-spacing: 0.1em;
    -moz-letter-spacing: 0.1em;
    -ms-letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 1.375em;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.3;
    background-size: 10px;
    padding-left: 26px;
    background-image: url(${Images.globalBtnNavBackBtn});
    background-position: left center;
    background-repeat: no-repeat;
    /* test */
  `}
`;

export const Features = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const { navigate } = props;

  const intlLocation = props.location.pathname.split("/")[1];

  return (
    <MainCanvas>
      {standAlone && (
        <TabBack
          onMouseUp={() =>
            navigate && navigate(`/${intlLocation}/ipad`, { replace: true })
          }
        >
          Home
        </TabBack>
      )}
      <FeaturesListView></FeaturesListView>
      <Navigation></Navigation>
      <a href="/" className="hidden-btn">
        &nbsp;&nbsp;
      </a>
    </MainCanvas>
  );
};
