import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Images } from "../constants/Images";
import {
  packageTitle1,
  tsMainBlocks,
  tsWidth,
  tsHeight,
} from "@ecomm/typography";
import { tsbgblack1, white } from "@ecomm/colors/base";
import { HasImage } from "types/common";
import { LoadSpinner } from "./UI/LoadSpinner";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { FeatureItem } from "types/features";
import { animated, useSpring } from "react-spring";
import { FeatureOverlay } from "./FeatureOverlay";

const MainFeatures = styled.div`
  ${tsWidth};
  ${tsHeight};
  height: 1920px;
  background-color: ${tsbgblack1};
  background-color: #181a1d;
  position: relative;
  color: ${white};
  display: block;
  /* background-image: url(${Images.globalTopGlow}); */
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  ${ccIpadPortrait`
    height: auto;
    padding-bottom: 100px;
  `}
`;

// const Logo = styled.div`
//   background-image: url(${Images.globalGlowLogo});
//   ${tsLogo};
// `;

const FeaturesLogo = styled.div`
  width: 400px;
  height: 100px;
  /* border: 1px solid red; */
  text-align: center;
  display: block;
  position: absolute;
  background-size: 44px 60px;
  top: 55px;
  left: calc(50% - 200px);
  font-weight: 700 !important;
  ${packageTitle1};
  font-size: 30px;
  ${ccIpadPortrait`
    width: 22px;
    height: 30px;
    background-size: contain;
    top: 40px;
    left: calc(50% - 11px);
  `}
`;

const FeatureList = styled.div`
  display: block;
  padding-top: 200px;
  ${ccIpadPortrait`
    padding-top: 110px;
  `}
`;

// const FeatureDetails = styled.a<HasImage>`
//   ${tsMainBlocks};
//   display: block;
//   background-image: url(${({ image }) => (image ? image : null)});
//   background-size: contain;
//   background-repeat: no-repeat;
//   margin: 0 0 38px 90px;
//   ${ccIpadPortrait`
//     margin: 0 auto 20px;
//   `}
// `;

const FeatureDetails0 = styled.a<HasImage>`
  ${tsMainBlocks};
  display: flex;
  background-color: black;
  background-image: url(${({ image }) => (image ? image : null)});
  /* background-size: contain; */
  background-size: 356px 187px;
  background-position: 84px 146px;
  background-repeat: no-repeat;
  margin: 0 0 38px 90px;
  border-radius: 8px;
  ${ccIpadPortrait`
    margin: 0 auto 20px;
  `}
`;

const FeatureDetails1 = styled.a<HasImage>`
  ${tsMainBlocks};
  display: flex;
  background-color: black;
  background-image: url(${({ image }) => (image ? image : null)});
  /* background-size: contain; */
  background-size: 232px 245px;
  background-position: 146px 120px;
  background-repeat: no-repeat;
  margin: 0 0 38px 90px;
  border-radius: 8px;
  ${ccIpadPortrait`
    margin: 0 auto 20px;
  `}
`;

const FeatureDetails2 = styled.a<HasImage>`
  ${tsMainBlocks};
  display: flex;
  background-color: black;
  background-image: url(${({ image }) => (image ? image : null)});
  /* background-size: contain; */
  background-size: 236px 230px;
  background-position: 146px 120px;
  background-repeat: no-repeat;
  margin: 0 0 38px 90px;
  border-radius: 8px;
  ${ccIpadPortrait`
    margin: 0 auto 20px;
  `}
`;

const FeatureContent = styled.div`
  ${tsMainBlocks};
  /* padding-left: 50%; */
  padding-left: 380px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FeatureRedArrow = styled.div`
  /* border: 1px solid blue; */
  width: 34px;
  height: 65px;
  position: absolute;
  left: 920px;
  margin-top: 203px;
  background-image: url(${Images.redArrowRight22});
`;

const FeatureTitle = styled.div`
  ${packageTitle1};
  font-weight: 600 !important;
  width: 320px;
  /* border: 1px solid red; */
  color: white;
  text-align: left;
  font-size: 50px;
  /* align-content: center;
  height: 400px; */
  ${ccIpadPortrait`
    padding: 100px 10px 0;
    margin-bottom: 8px;
  `}
  span {
    display: block;
    font-size: 28px;
  }
`;

const OverlayAnim = styled(animated.div)`
  position: relative;
  z-index: 1001;
`;

export const FeaturesListView = () => {
  const features: FeatureItem[] = [
    { title: "Form assist", imageUrl: "rowFormAssistFeature" },
    { title: "Form rating and insights", imageUrl: "rowRatingFeature" },
    { title: "Personal pace targets", imageUrl: "rowPaceFeature" },
  ];
  // const navigate = useNavigate();
  // const location = useLocation();

  const [preloadComplete, setPreloadComplete] = useState<boolean>(false);
  const [imgArr, setImgArr] = useState<string[]>([]);
  const [chosenFeature, setChosenFeature] = useState<string>("");
  const [chosenTitle, setChosenTitle] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(false);

  const overlayAnimSpring = useSpring({
    opacity: showOverlay ? 1 : 0,
  });

  const selectOverlay = (feat: string, title: string) => {
    setChosenFeature(feat);
    setChosenTitle(title);
    toggleOverlay();
  };

  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  // const locationArray = location.pathname.split("/");

  // const intlLocation = locationArray[1];
  // let catalogSlug = "dotcom";
  // let catalogLocale = "EN_US";
  // let catalogCurrency = "USD";
  // let apiLocale = "en-US";
  // if (intlLocation === "ca") {
  //   catalogSlug = "ca_webstore";
  //   catalogLocale = "EN_CA";
  //   catalogCurrency = "CAD";
  //   apiLocale = "en-CA";
  // }
  //slug: "ca_webstore", locale: EN_CA, currency: CAD
  // const curSection = locationArray[2];
  // const curCategory = locationArray[4];

  useEffect(() => {
    // XXX: hardcoded for row
    if (imgArr.length === 0) {
      setImgArr(features.map((feature) => Images[feature.imageUrl]));
    }
    if (!preloadComplete && imgArr.length > 0) {
      const promises: Promise<string>[] = [];
      const cacheImages = (arr: string[]) => {
        arr.forEach((src) => {
          const p = new Promise<string>((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve(src);
            img.onerror = () => reject();
          });
          promises.push(p);
        });
        Promise.all(promises).then((results) => {
          setIsLoading(false);
          setPreloadComplete(true);
        });
      };
      cacheImages(imgArr);
    }
  }, [preloadComplete, imgArr, features]);

  return (
    <MainFeatures>
      <OverlayAnim style={overlayAnimSpring}>
        {showOverlay && (
          <FeatureOverlay
            image={chosenFeature}
            title={chosenTitle}
            hideOverlay={toggleOverlay}
          ></FeatureOverlay>
        )}
      </OverlayAnim>
      {/* <Logo></Logo> */}
      <FeaturesLogo>
        Features
        <br />
        That Motivate
      </FeaturesLogo>
      {isLoading ? (
        <LoadSpinner>Loading...</LoadSpinner>
      ) : (
        <FeatureList>
          {/* {features.map((f, i) => {
            return (
              <FeatureDetails
                onMouseUp={() => console.log(f)}
                key={i}
                image={imgArr[i]}
              >
                <FeatureContent>
                  <FeatureTitle>{f.title}</FeatureTitle>
                </FeatureContent>
              </FeatureDetails>
            );
          })} */}
          <FeatureDetails0
            onMouseUp={() =>
              selectOverlay("rowDetailFormAssist", features[0].title)
            }
            key={0}
            image={imgArr[0]}
          >
            <FeatureContent>
              <FeatureTitle>
                {/* {features[0].title} */}Form
                <br />
                Assist
              </FeatureTitle>
            </FeatureContent>
            <FeatureRedArrow></FeatureRedArrow>
          </FeatureDetails0>
          <FeatureDetails1
            onMouseUp={() =>
              selectOverlay("rowDetailRating", features[1].title)
            }
            key={1}
            image={imgArr[1]}
          >
            <FeatureContent>
              <FeatureTitle>
                {/* {features[1].title} */}Form Rating <span>and</span>Insights
              </FeatureTitle>
            </FeatureContent>
            <FeatureRedArrow></FeatureRedArrow>
          </FeatureDetails1>
          <FeatureDetails2
            onMouseUp={() => selectOverlay("rowDetailPace", features[2].title)}
            key={2}
            image={imgArr[2]}
          >
            <FeatureContent>
              <FeatureTitle>{features[2].title}</FeatureTitle>
            </FeatureContent>
            <FeatureRedArrow></FeatureRedArrow>
          </FeatureDetails2>
        </FeatureList>
      )}
    </MainFeatures>
  );
};
