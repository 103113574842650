import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Navigation } from "components/Navigation";
import { MainCanvas } from "components/UI/MainCanvas";

import { Images } from "constants/Images";
import styled from "styled-components";
import { ccIpadPortrait } from "@peloton/styles/breakpoints";
import { app2Headline } from "@ecomm/typography";

const TabBack = styled.a`
  display: none;

  ${ccIpadPortrait`
    position: absolute;
    left: 122px;
    top: 48px;
    z-index: 20;
    color: #888b93;
    display: block;
    font-weight: 700;
    font-family: "Inter", sans-serif;
    -webkit-letter-spacing: 0.1em;
    -moz-letter-spacing: 0.1em;
    -ms-letter-spacing: 0.1em;
    letter-spacing: 0.1em;
    line-height: 1.375em;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 1.3;
    background-size: 10px;
    padding-left: 26px;
    background-image: url(${Images.globalBtnNavBackBtn});
    background-position: left center;
    background-repeat: no-repeat;
    /* test */
  `}
`;

const AppSubcontainer = styled.div`
  display: block;
  position: relative;
  padding: 83px 0px 150px 90px;
  background: #181a1d;
  min-height: 100vh;
`;

const AppTitle = styled.div`
  color: #cdff38;
  text-transform: uppercase;
  font-size: 30px;
  font-family: "Inter", sans-serif;
  position: absolute;
  left: 93px;
  top: 80px;
  font-weight: 700;
`;

const AppSubtitle = styled.div`
  padding-top: 50px;
  display: block;
  position: relative;
  margin-bottom: 67px;
  ${app2Headline}
`;

// const AppTile = styled.div`
//   display: block;
//   position: relative;
//   width: 900px;
//   height: auto;
//   margin-bottom: 46px;
//   img {
//     width: 900px;
//     height: auto;
//   }
// `;

const AppPhoneFrame = styled.div`
  width: 841px;
  height: 656px;
  background-image: url(${Images.atGym2frame});
  background-size: contain;
  display: block;
  position: absolute;
  text-align: center;
  margin-left: 22px;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  color: white;
  h3 {
    margin: 0;
    font-weight: 400;
    font-size: 34px;
    padding: 100px 0 30px;
    letter-spacing: 1.5px;
  }
  span {
    font-size: 70px;
    font-weight: 400;
    letter-spacing: 2px;
  }
`;

const AppPhone = styled.div`
  width: 851px;
  height: 1749px;
  background-image: url(${Images.atGym2phone});
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  z-index: 2;
  margin: 400px 0 100px 12px;
`;

export const Collections = (props: RouteComponentProps) => {
  const standAlone = window.matchMedia("(display-mode: standalone)").matches;
  const { navigate } = props;

  const intlLocation = props.location.pathname.split("/")[1];

  return (
    <MainCanvas>
      {standAlone && (
        <TabBack
          onMouseUp={() =>
            navigate && navigate(`/${intlLocation}/ipad`, { replace: true })
          }
        >
          Home
        </TabBack>
      )}
      <AppSubcontainer>
        <AppTitle>Peloton &nbsp; App</AppTitle>
        <AppSubtitle>
          Instructor-designed
          <br /> gym plans
        </AppSubtitle>
        <AppPhoneFrame>
          <h3>Introducing</h3>
          <span>Peloton Gym</span>
        </AppPhoneFrame>
        <AppPhone></AppPhone>

        {/* <AppTile>
          <img alt="" src={Images.app2gpGp1} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app2gpGp2} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app2gpGp3} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app2gpGp4} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app2gpGp5} />
        </AppTile>
        <AppTile>
          <img alt="" src={Images.app2gpGp6} />
        </AppTile> */}
      </AppSubcontainer>
      <Navigation></Navigation>
      <a href="/" className="hidden-btn">
        &nbsp;&nbsp;
      </a>
    </MainCanvas>
  );
};
